import React from "react";

// ASSETS

// STYLES
import { FooterContainer, FooterContent } from "./FooterStyled";

// LIBRARIES

// MISC
import { COLOR, FONT_SIZE } from "../../constants/constants";

// REDUX

// COMPONENTS
import TextAtom from "../atoms/TextAtom/TextAtom";

const Footer = () => {
  // PROPERTIES

  // LIBRARY CONSTANTS

  // STATE CONSTANTS

  // API REQUESTS

  // LIFE CYCLE

  // EVENT HANDLERS

  return (
    <FooterContainer>
      <FooterContent>
        <TextAtom
          text="© Uppark.io, 2024"
          fontSize={FONT_SIZE.max16}
          fontWeight="400"
          color={COLOR.appWhite}
        />
      </FooterContent>
    </FooterContainer>
  );
};

export default Footer;
