
// ASSETS

// STYLES
import { HeaderContainer, HeaderContent } from "./HeaderStyled";

// LIBRARIES

// MISC
import { COLOR, FONT_SIZE } from "../../constants/constants";

// REDUX

// COMPONENTS
import TextAtom from "../atoms/TextAtom/TextAtom";

const Header = () => {
  // PROPERTIES

  // LIBRARY CONSTANTS

  // STATE CONSTANTS


  // API REQUESTS

  // LIFE CYCLE
  // EVENT HANDLERS

  return (
    <HeaderContainer>
      <HeaderContent>
        <TextAtom
          text={`PLATA PARCARE`}
          fontSize={FONT_SIZE.max24}
          fontWeight="700"
          color={COLOR.appBlack}
        />
      </HeaderContent>
    </HeaderContainer>
  );
};

export default Header;
