import styled from "styled-components";

export const ProductFormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 12px;
`;

export const ProductFormBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 16px;
`;

export const ProductFormTitleWrapper = styled.div`
  padding-block: 8px;
  text-align: center;
`;

export const ProductFormInputWrapper = styled.div`
  height: 72px;
  width: 730px;
  @media (width<768px) {
    width: 90vw;
  }
`;

export const ProductFormSelectWrapper = styled.div`
  height: 72px;
  width: 730px;
  @media (width<768px) {
    width: 90vw;
  }
`;

export const ProductFormButtonWrapper = styled.div`
  height: 75px;
  width: 730px;
  margin-top: 12px;
  @media (width<768px) {
    width: 90vw;
  }
`;
