import { useState } from "react";

// ASSETS

// STYLES
import { PaymentsContainer } from "./PaymentsStyled";

// LIBRARIES
import { useParams } from "react-router-dom";

// MISC

// REDUX

// COMPONENTS
import BillingForm from "../BillingForm/BillingForm";
import LicensePlateForm from "../LicensePlateForm/LicensePlateForm";
import ProductForm from "../ProductForm/ProductForm";

const Payments = () => {
  // PROPERTIES

  // LIBRARY CONSTANTS
  const { parkingId } = useParams();

  const [step, setStep] = useState(1);
  const [paymentDetails, setPaymentDetails] = useState({
    parkingId,
    licensePlate: "",
    selectedProduct: {},
  });
  // STATE CONSTANTS

  // API REQUESTS

  // LIFE CYCLE

  // EVENT HANDLERS
  const handleNextStep = () => {
    setStep(step + 1);
  };

  const handlePreviousStep = () => {
    setStep(step - 1);
  };

  const handleFormRender = () => {
    switch (step) {
      default:
      case 1:
        return <LicensePlateForm onNextStep={handleNextStep} paymentDetails={paymentDetails} setPaymentDetails={setPaymentDetails} />;
      case 2:
        return <ProductForm onNextStep={handleNextStep} paymentDetails={paymentDetails} setPaymentDetails={setPaymentDetails} />;
      case 3:
        return <BillingForm onPreviousStep={handlePreviousStep} paymentDetails={paymentDetails} />;
    }
  };

  return <PaymentsContainer>{handleFormRender()}</PaymentsContainer>;
};

export default Payments;
