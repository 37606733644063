import { useState, useRef, useEffect } from "react";

// ASSETS
import ArrowDownIcon from "../../../assets/icons/arrowDown.svg";
// STYLES
import {
  SelectAtomContainer,
  SelectArrow,
  SelectArrowWrapper,
  SelectButton,
  SelectMenuContainer,
  SelectMenuItem,
  SelectTextWrapper,
  SelectAtomLabel,
} from "./SelectAtomStyled";

// LIBRARIES

// MISC
import { IProps, IOptionsStruct } from "./SelectAtomModel";
import { COLOR, FONT_SIZE } from "../../../constants/constants";
// REDUX

// COMPONENTS
import TextAtom from "../TextAtom/TextAtom";

const SelectAtom = (props: IProps) => {
  // PROPERTIES
  const {
    options = [
      {
        key: "",
        value: "",
      },
    ],
    value = "",
    placeholder = "",
    label = "",
    onChange = () => { },
  } = props;
  // LIBRARY CONSTANTS
  const menuRef = useRef(null);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  // STATE CONSTANTS

  // API REQUESTS

  // LIFE CYCLE
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        menuRef.current &&
        !(menuRef.current as any).contains(event.target as Node)
      ) {
        setMenuOpen(false);
      }
    };

    if (menuOpen) {
      document.addEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [menuOpen]);

  // EVENT HANDLERS
  const handleMenuOpen = () => {
    setMenuOpen((prevState) => !prevState);
  };

  const handleSelectOption = (option: IOptionsStruct) => {
    onChange(option);
    setMenuOpen(false);
  };

  return (
    <SelectAtomContainer ref={menuRef}>
      {label.length > 0 && <SelectAtomLabel>{label}</SelectAtomLabel>}
      <SelectButton onClick={() => handleMenuOpen()}>
        <SelectTextWrapper>
          <TextAtom
            text={value.length > 0 ? value : placeholder}
            fontSize={FONT_SIZE.max20}
            fontWeight="600"
            color={value.length > 0 ? COLOR.appBlack : COLOR.appBlack + "40"}
          />
        </SelectTextWrapper>
        <SelectArrowWrapper>
          <SelectArrow src={ArrowDownIcon} alt="arrow" open={menuOpen} />
        </SelectArrowWrapper>
      </SelectButton>
      <SelectMenuContainer open={menuOpen}>
        {options?.map((option: IOptionsStruct) => (
          <SelectMenuItem
            key={option.key}
            onClick={() => handleSelectOption(option)}
            selected={option.value === value}
          >
            <TextAtom
              text={option.value}
              fontSize={FONT_SIZE.max16}
              fontWeight="600"
              color={COLOR.appBlack}
            />
          </SelectMenuItem>
        ))}
      </SelectMenuContainer>
    </SelectAtomContainer>
  );
};

export default SelectAtom;
