import React from "react";

// ASSETS

// STYLES
import { TabAtomContainer, TabAtomStyled } from "./TabAtomStyled";

// LIBRARIES

// MISC
import { IProps } from "./TabAtomModel";
import { FONT_SIZE, COLOR } from "../../../constants/constants";
// REDUX

// COMPONENTS
import TextAtom from "../TextAtom/TextAtom";

const TabAtom = (props: IProps) => {
  // PROPERTIES
  const { isSelected = false, title = "", subtitle = "" } = props;
  // LIBRARY CONSTANTS

  // STATE CONSTANTS

  // API REQUESTS

  // LIFE CYCLE

  // EVENT HANDLERS

  return (
    <TabAtomContainer>
      <TabAtomStyled isSelected={isSelected}>
        <TextAtom
          text={title}
          fontSize={FONT_SIZE.max10}
          fontWeight="700"
          color={isSelected ? COLOR.appBlack : COLOR.appBlack + "40"}
        />
        <TextAtom
          text={subtitle}
          fontSize={FONT_SIZE.max16}
          fontWeight="600"
          color={isSelected ? COLOR.appBlack : COLOR.appBlack + "40"}
        />
      </TabAtomStyled>
    </TabAtomContainer>
  );
};

export default TabAtom;
