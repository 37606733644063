import styled from "styled-components";
import { FONT, FONT_SIZE, COLOR } from "../../../constants/constants";

export const InputAtomContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const InputAtomInput = styled.input`
  width: 100%;
  height: 100%;
  padding-inline: 24px;
  border: 1px solid ${COLOR.appGrey};
  box-shadow: 0 1px 6px 0 ${COLOR.boxShadowGray}7f;
  border-radius: 4px;
  background-color: ${COLOR.appWhite};
  font-size: ${FONT_SIZE.max20};
  font-weight: 600;
  color: ${COLOR.appBlack};
  font-family: ${FONT.gotham};

  &:focus {
    outline: none;
    border: 1px solid ${COLOR.appBlue};
  }
  &::placeholder {
    color: ${COLOR.appBlack}40;
  }
`;

export const InputAtomLabel = styled.label`
  padding-inline: 24px;
  padding-block: 4px;
  display: flex;
  align-items: center;

  font-size: ${FONT_SIZE.max10};
  font-weight: 700;
  text-transform: uppercase;
  color: ${COLOR.appBlack};
  font-family: ${FONT.gotham};
`;
