import styled from "styled-components";

export const BillingFormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 12px;
  padding-bottom: 50px;
`;

export const BillingFormBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 16px;
  width: 730px;
  @media (width<768px) {
    width: 90vw;
  }
`;

export const BillingFormTitleWrapper = styled.div`
  padding-block: 8px;
  text-align: center;
`;
export const BillingFormIputsContainer = styled.div`
  width: 100%;
`;

export const BillingFormInputsRow = styled.div``;

export const BillingFormInputDoubleRow = styled.div`
  display: flex;
  width: 100%;
  column-gap: 30px;

  @media (width<768px) {
    display: block;
  }
`;

export const BillingFormInputWrapper = styled.div`
  height: 72px;
  width: 730px;
  display: flex;
  column-gap: 30px;
  @media (width<768px) {
    margin-block: 12px;
    width: 90vw;
  }
`;
export const BillingFormTabsWrapper = styled.div`
  height: 64px;
  width: 730px;
  display: flex;
  @media (width<768px) {
    width: 90vw;
  }
`;

export const BillingFormTabWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const BillingFormButtonWrapper = styled.div`
  height: 75px;
  width: 730px;
  margin-top: 12px;
  display: flex;
  column-gap: 30px;
  @media (width<768px) {
    width: 90vw;
    height: 160px;
    flex-direction: column;
    row-gap: 24px;
  }
`;
