import styled from "styled-components";
import { COLOR } from "../../constants/constants";

export const FooterContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export const FooterContent = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    135deg,
    ${COLOR.appBlue} 0,
    ${COLOR.appPurple} 100%
  );
`;
