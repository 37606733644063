import axios from "axios";

//export const baseURL: string = 'https://cors-anywhere.herokuapp.com/http://20.33.25.47/api';
//export const baseURL: string = 'http://20.33.25.47/api'
export const baseURL: string = 'https://qr.uppark.io/api';

export const REST = axios.create({
  baseURL: baseURL,
  timeout: 30000,
  headers: {},
});

// REST.interceptors.request.use(
//   async (config) => {
//     const token = store.getState()?.session?.token || null;
//     if (token) {
//       config.headers["Authorization"] = `Bearer ${token}`;
//     }
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

export const checkStatus = (status: any) => {
  return {
    is200: status === 200,
    is202: status === 202,
    is401: status === 401,
    is403: status === 403,
    is405: status === 405,
    is404: status === 404,
    is500: status === 500,
    is501: status === 501,
  };
};

REST.interceptors.response.use(
  (response) => {
    return new Promise((resolve, reject) => {
      resolve(response);
    });
  },
  async (error) => {
    const requestStatus = checkStatus(error.response.status);

    // the user is not authorized
    if (requestStatus.is401) {
      // If token is not valid or expired clear lscache.
      // Also, here you can do refresh token API call
      // if (error.response.config.url !== 'authenticate') {
      window.location.href = "/";
      //   lscache.flush()
      // }
    }
    return Promise.reject(error);
  }
);
