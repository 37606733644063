import styled from "styled-components";

export const PaymentWebViewContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  row-gap: 12px;
`;

export const PaymentWebViewBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 16px;
`;

export const PaymentWebViewTitleWrapper = styled.div`
  padding-block: 8px;
  text-align: center;
`;

export const PaymentWebViewInputWrapper = styled.div`
  height: 72px;
  width: 730px;
  @media (width<768px) {
    width: 90vw;
  }
`;

export const PaymentWebViewSelectWrapper = styled.div`
  height: 72px;
  width: 730px;
  @media (width<768px) {
    width: 90vw;
  }
`;

export const PaymentWebViewButtonWrapper = styled.div`
  height: 75px;
  width: 730px;
  margin-top: 12px;
  @media (width<768px) {
    width: 90vw;
  }
`;

export const PaymentWebViewWrapper = styled.div`
  height: 50vh;
  width: 100%;
  @media (width<768px) {
    width: 100%;
    height: 100%;
  }
`;
