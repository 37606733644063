import React from "react";

import { BrowserRouter, Route, Routes } from "react-router-dom";

// COMPONENTS
import AppRoutes from "../src/config/AppRoutes";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={"/*"} element={<AppRoutes />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
