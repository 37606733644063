export const SIZE = {};

export const COLOR = {
  appBlack: "#212121",
  appBlue: "#0054c1",
  appWhite: "#fff",
  appGrey: "#eaeaea",
  confirmGeen: "#027777",
  boxShadowGray: "#cdcdcd",
  disabledGray: "#aaa",
  appPurple: "#743770",
};

export const FONT = {
  gotham: "Gotham Rounded",
};

export const FONT_SIZE = {
  max8: "clamp(6px, 1.5vw, 8px)",
  max10: "clamp(8px, 2vw, 10px)",
  max12: "clamp(10px, 2.5vw, 12px)",
  max14: "clamp(12px, 3vw, 14px)",
  max16: "clamp(14px, 3.5vw, 16px)",
  max18: "clamp(16px, 4vw, 18px)",
  max20: "clamp(18px, 4.5vw, 20px)",
  max22: "clamp(20px, 5vw, 22px)",
  max24: "clamp(22px, 5.5vw, 24px)",
  max26: "clamp(24px, 6vw, 26px)",
  max28: "clamp(26px, 6.5vw, 28px)",
  max30: "clamp(28px, 7vw, 30px)",
};
