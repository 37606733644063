import { useEffect, useState } from "react";
// ASSETS
import Icon_Cancel from "../../assets/icons/Icon_Cancel.svg";
import Icon_Checked from "../../assets/icons/Icon_Checked.svg";

// STYLES
import {
  PaymentDetailsButtonWrapper,
  PaymentDetailsContainer,
  PaymentDetailsDoneIconWrapper,
  PaymentDetailsHelpWrapper,
  PaymentDetailsIcon,
  PaymentDetailsTextWrapper,
} from "./PaymentDetailsStyled";

// LIBRARIES
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";

// MISC
import { COLOR, FONT_SIZE } from "../../constants/constants";
// REDUX

// COMPONENTS
import ButtonAtom from "../../components/atoms/ButtonAtom/ButtonAtom";
import TextAtom from "../../components/atoms/TextAtom/TextAtom";

const PaymentDetails = () => {
  // PROPERTIES

  // LIBRARY CONSTANTS
  const navigate = useNavigate();
  const location = useLocation();
  const { payload, selectedProduct, status } = location.state || {};

  // STATE CONSTANTS
  const [period, setPeriod] = useState("");

  // API REQUESTS

  // LIFE CYCLE
  // eslint-disable-next-line
  useEffect(() => handleFormatPeriod(), []);

  // EVENT HANDLERS
  const handleClick = () => {
    navigate(`/payment-form/${localStorage.getItem("parkingId")}`);
  };

  const handleFormatPeriod = () => {
    const startDate = moment();

    // Calculate the end date by adding the duration in minutes
    const endDate = moment().add(selectedProduct.durationMinutes, "minutes");

    // Format the start and end dates
    const formattedStartDate = startDate.format("DD/MM/YYYY HH:mm");
    const formattedEndDate = endDate.format("DD/MM/YYYY HH:mm");

    setPeriod(`${formattedStartDate} - ${formattedEndDate}`);
  };

  return (
    <PaymentDetailsContainer>
      <PaymentDetailsDoneIconWrapper>
        {status === "Completed" && (
          <PaymentDetailsIcon src={Icon_Checked} alt="completed" />
        )}
        {status === "Canceled" && (
          <PaymentDetailsIcon src={Icon_Cancel} alt="canceled" />
        )}
      </PaymentDetailsDoneIconWrapper>
      <PaymentDetailsTextWrapper>
        {status === "Completed" && (
          <TextAtom
            text="Gata!"
            fontSize={FONT_SIZE.max20}
            color={COLOR.appBlack}
            fontWeight="600"
          />
        )}
        <TextAtom
          text={
            status === "Completed"
              ? "Plata a fost inregistrată cu success!"
              : "A apărut o problemă. Te rog reîncearcă!"
          }
          fontSize={FONT_SIZE.max20}
          color={COLOR.appBlack}
          fontWeight="600"
        />
      </PaymentDetailsTextWrapper>
      {status === "Completed" && (
        <PaymentDetailsTextWrapper>
          <TextAtom
            text={`NUMAR INMATRICULARE: ${payload.licensePlate}`}
            fontSize={FONT_SIZE.max12}
            color={COLOR.appBlack}
            fontWeight="700"
          />
          <TextAtom
            text={`ZONA: ${localStorage.getItem(
              "parkingTitle"
            )} - ${localStorage.getItem("parkingSubtitle")}`}
            fontSize={FONT_SIZE.max12}
            color={COLOR.appBlack}
            fontWeight="700"
          />
          <TextAtom
            text={`PERIOADA: ${period}`}
            fontSize={FONT_SIZE.max12}
            color={COLOR.appBlack}
            fontWeight="700"
          />
        </PaymentDetailsTextWrapper>
      )}
      <PaymentDetailsHelpWrapper>
        <PaymentDetailsTextWrapper>
          <TextAtom
            text="INTAMPINATI PROBLEME? TRIMITE UN EMAIL:"
            fontSize={FONT_SIZE.max12}
            color={COLOR.appWhite}
            fontWeight="700"
          />
          <TextAtom
            text="SUPPORT@UPPARK.IO"
            fontSize={FONT_SIZE.max12}
            color={COLOR.appWhite}
            fontWeight="700"
          />
        </PaymentDetailsTextWrapper>
      </PaymentDetailsHelpWrapper>
      <PaymentDetailsButtonWrapper>
        <ButtonAtom
          text="Plata noua"
          handleClick={handleClick}
          disabled={false}
        />
      </PaymentDetailsButtonWrapper>
    </PaymentDetailsContainer>
  );
};

export default PaymentDetails;
