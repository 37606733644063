import React from "react";

// ASSETS

// STYLES
import { ButtonAtomContainer, ButtonAtomStyled } from "./ButtonAtomStyled";

// LIBRARIES

// MISC
import { IProps } from "./ButtonAtomModel";
import { FONT_SIZE, COLOR } from "../../../constants/constants";
// REDUX

// COMPONENTS
import TextAtom from "../TextAtom/TextAtom";

const ButtonAtom = (props: IProps) => {
  // PROPERTIES
  const { text = "", handleClick = () => {}, disabled = false } = props;
  // LIBRARY CONSTANTS

  // STATE CONSTANTS

  // API REQUESTS

  // LIFE CYCLE

  // EVENT HANDLERS

  return (
    <ButtonAtomContainer>
      <ButtonAtomStyled onClick={handleClick} disabled={disabled}>
        <TextAtom
          text={text}
          fontSize={FONT_SIZE.max20}
          fontWeight="700"
          color={disabled ? COLOR.disabledGray : COLOR.appBlack}
        />
      </ButtonAtomStyled>
    </ButtonAtomContainer>
  );
};

export default ButtonAtom;
