import styled from "styled-components";
import PhoneInput from "react-phone-number-input";
import { FONT, FONT_SIZE, COLOR } from "../../constants/constants";

export const PhoneInputContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const PhoneInputComponent = styled(PhoneInput)`
  width: 100%;
  height: 100%;
  border: 1px solid ${COLOR.appGrey};
  box-shadow: 0 1px 6px 0 ${COLOR.boxShadowGray}7f;
  border-radius: 4px;
  background-color: ${COLOR.appWhite};
  font-size: ${FONT_SIZE.max20};
  font-weight: 600;
  color: ${COLOR.appBlack};
  font-family: ${FONT.gotham};

  &::placeholder {
    color: ${COLOR.appBlack}40;
    font-family: ${FONT.gotham};
  }
  &:focus {
    outline: none;
    border: 1px solid ${COLOR.appBlue};
  }
  .PhoneInputCountry {
    width: 75px;
    height: 100%;
  }

  .PhoneInputCountryIcon {
    width: 100%;
    height: 100%;
  }
  .PhoneInputCountryIcon--border {
    background-color: transparent;
    box-shadow: none;
  }
  .PhoneInput {
    background-color: transparent;
    box-shadow: none;
    .PhoneInput--focus {
      box-shadow: none;
    }
  }
  .PhoneInputCountrySelect {
    outline: 1px solid ${COLOR.appGrey};
    border-radius: 4px;
    background-color: ${COLOR.appWhite};
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);

    padding: 24px;
    font-size: ${FONT_SIZE.max20};
    font-weight: 600;
    color: ${COLOR.appBlack};
    font-family: ${FONT.gotham};

    & > option {
      padding-block: 12px;
    }

    & > option:hover {
      background-color: ${COLOR.appGrey} !important;
    }
  }

  .PhoneInputInput {
    border: none;
    width: 100%;
    height: 100%;
    padding-inline: 24px;
    font-size: ${FONT_SIZE.max20};
    font-weight: 600;
    color: ${COLOR.appBlack};
    font-family: ${FONT.gotham};
    &:focus {
      outline: none;
      border: 1px solid ${COLOR.appBlue};
      border-radius: 4px;
    }
  }

  .PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
    box-shadow: none;
    border: 1px solid ${COLOR.appBlue};
    border-radius: 4px;
  }
`;

export const PhoneInputLabel = styled.label`
  padding-inline: 24px;
  padding-block: 4px;
  display: flex;
  align-items: center;

  font-size: ${FONT_SIZE.max10};
  font-weight: 700;
  text-transform: uppercase;
  color: ${COLOR.appBlack};
  font-family: ${FONT.gotham};
`;

export const PhoneInputMenu = styled.select`
  list-style: none;
  position: absolute;
  width: 400px;

  z-index: 1;
  top: 100%;
  padding: 0;
  margin: 0;
  border: 1px solid ${COLOR.appGrey};
  border-radius: 4px;
  background-color: ${COLOR.appWhite};
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  max-height: 500px;

  & > *:hover {
    background-color: ${COLOR.appWhite};
  }
  @media (width<768px) {
    max-height: 300px;
    width: 100%;
  }
`;
