import { useEffect } from "react";
// ASSETS

// STYLES
import { TitleWrapper } from "../LicensePlateForm/LicensePlateFormStyled";
import { PaymentWebViewContainer, PaymentWebViewTitleWrapper, PaymentWebViewWrapper } from "./PaymentWebViewPageStyled";

// LIBRARIES
import { useLocation, useNavigate } from "react-router-dom";

// MISC
import { REST } from "../../config/axiosConfig";
import { COLOR, FONT_SIZE } from "../../constants/constants";
import { IProps } from "./PaymentWebViewPageModel";

// REDUX

// COMPONENTS
import TextAtom from "../../components/atoms/TextAtom/TextAtom";

const PaymentWebView = (props: IProps) => {
  // PROPERTIES

  // LIBRARY CONSTANTS
  const navigate = useNavigate();
  const location = useLocation();
  const { data, payload, selectedProduct } = location.state || {};

  // STATE CONSTANTS

  // API REQUESTS

  // LIFE CYCLE
  useEffect(() => {
    const checkTransaction = async () => {
      await REST.get(`/Payment/check-transaction-state/${data.transactionId}`)
        .then((response: any) => {
          if (response.data.status === "Completed") {
            navigate(`/payment-status/${data.transactionId}`, { state: { payload, selectedProduct, status: "Completed" } });
          }
          if (response.data.status === "Canceled") {
            navigate(`/payment-status/${data.transactionId}`, { state: { payload, selectedProduct, status: "Canceled" } });
          }
        })
        .catch((error: any) => { })
    }

    const interval = setInterval(() => checkTransaction(), 2500)

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, [])

  // EVENT HANDLERS

  return (
    <PaymentWebViewContainer>
      <PaymentWebViewTitleWrapper>
        <TitleWrapper>
          <TextAtom
            text={`Parcare: ${localStorage.getItem("parkingTitle")} - ${localStorage.getItem("parkingSubtitle")}`}
            fontSize={FONT_SIZE.max16}
            color={COLOR.appBlack}
          />
        </TitleWrapper>
        <TextAtom
          text="Plata in curs"
          fontSize={FONT_SIZE.max16}
          color={COLOR.appBlack}
        />
      </PaymentWebViewTitleWrapper>
      <PaymentWebViewWrapper>
        <iframe
          srcDoc={data.form}
          style={{ height: '100%', width: '100%', border: 'none' }}
          title="Payment Form"
        />
      </PaymentWebViewWrapper>
    </PaymentWebViewContainer>
  );
};

export default PaymentWebView;
