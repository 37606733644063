import { useEffect, useState } from "react";
// ASSETS

// STYLES
import {
  ProductFormBodyWrapper,
  ProductFormButtonWrapper,
  ProductFormContainer,
  ProductFormSelectWrapper,
  ProductFormTitleWrapper,
} from "./ProductFormStyled";

// LIBRARIES

// MISC
import { IOptionsStruct } from "../../components/atoms/SelectAtom/SelectAtomModel";
import { REST } from "../../config/axiosConfig";
import { COLOR, FONT_SIZE } from "../../constants/constants";
import { IProps } from "./ProductFormModel";

// REDUX

// COMPONENTS
import ButtonAtom from "../../components/atoms/ButtonAtom/ButtonAtom";
import SelectAtom from "../../components/atoms/SelectAtom/SelectAtom";
import TextAtom from "../../components/atoms/TextAtom/TextAtom";
import { TitleWrapper } from "../LicensePlateForm/LicensePlateFormStyled";

const ProductForm = (props: IProps) => {
  // PROPERTIES
  const {
    onNextStep = () => {},
    paymentDetails = {},
    setPaymentDetails = () => {},
  } = props;

  // LIBRARY CONSTANTS
  const [products, setProducts] = useState([] as any[]);

  // STATE CONSTANTS
  const regex = /^[A-Za-z0-9]+$/;

  const evaluateDisabledFirstStep =
    // true ||
    regex.test(paymentDetails.licensePlate.toUpperCase()) &&
    paymentDetails.selectedProduct?.value?.length > 0;

  // API REQUESTS
  const getProducts = async () => {
    await REST.get(
      `/Parkings/available-products/${paymentDetails.parkingId}/${paymentDetails.licensePlate}`
    ).then((response) => {
      const formattedProducts = response.data.map((product: any) => {
        return {
          key: product.productId,
          value: `${product.name} - ${product.price} RON`,
          durationMinutes: product.durationMinutes,
        };
      });
      setProducts(formattedProducts);
    });
  };

  // LIFE CYCLE
  useEffect((): void => {
    getProducts();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setPaymentDetails({
      ...paymentDetails,
      licensePlate: paymentDetails.licensePlate.toUpperCase(),
    });
    // eslint-disable-next-line
  }, []);

  // EVENT HANDLERS

  // const handleSelectParking = (option: IOptionsStruct) => {
  //   setPaymentDetails({ ...paymentDetails, selectedParking: option.value });
  // };

  const handleSelectProduct = (option: IOptionsStruct) => {
    setPaymentDetails({ ...paymentDetails, selectedProduct: option });
  };

  const handleClick = () => {
    onNextStep();
  };

  return (
    <ProductFormContainer>
      <ProductFormTitleWrapper>
        <TitleWrapper>
          <TextAtom
            text={`Parcare: ${localStorage.getItem(
              "parkingTitle"
            )} - ${localStorage.getItem("parkingSubtitle")}`}
            fontSize={FONT_SIZE.max16}
            color={COLOR.appBlack}
          />
        </TitleWrapper>
        <TextAtom
          text="Selectează prudusul dorit"
          fontSize={FONT_SIZE.max16}
          color={COLOR.appBlack}
        />
      </ProductFormTitleWrapper>
      <ProductFormBodyWrapper>
        {/* <ProductFormSelectWrapper>
          <SelectAtom
            options={parkingOptions}
            value={paymentDetails.selectedParking}
            onChange={handleSelectParking}
            placeholder="Selecteaza o parcare"
            label="PARCARE*"
          />
        </ProductFormSelectWrapper> */}
        <ProductFormSelectWrapper>
          <SelectAtom
            options={products}
            value={paymentDetails.selectedProduct.value}
            onChange={handleSelectProduct}
            placeholder="Selecteaza un produs"
            label="produs*"
          />
        </ProductFormSelectWrapper>
        <ProductFormButtonWrapper>
          <ButtonAtom
            text="Continua"
            handleClick={handleClick}
            disabled={!evaluateDisabledFirstStep}
          />
        </ProductFormButtonWrapper>
      </ProductFormBodyWrapper>
    </ProductFormContainer>
  );
};

export default ProductForm;
