import styled, { css } from "styled-components";
import { FONT } from "../../../constants/constants";

export const TextAtomContainer = styled.div``;

export const TextAtomStyled = styled.span<{
  fontSize: string;
  fontWeight: string;
  color: string;
}>(
  ({ fontSize, fontWeight, color }) => css`
    && {
      font-size: ${fontSize};
      font-weight: ${fontWeight};
      color: ${color};
      font-family: ${FONT.gotham};
    }
  `
);
