import { useEffect, useState } from "react";
// ASSETS

// STYLES
import {
  LicensePlateFormBodyWrapper,
  LicensePlateFormButtonWrapper,
  LicensePlateFormContainer,
  LicensePlateFormInputWrapper,
  LicensePlateFormTitleWrapper,
  TitleWrapper,
} from "./LicensePlateFormStyled";

// LIBRARIES
import { useParams } from "react-router-dom";

// MISC
import { REST } from "../../config/axiosConfig";
import { COLOR, FONT_SIZE } from "../../constants/constants";
import { IProps } from "./LicensePlateFormModel";

// REDUX

// COMPONENTS
import ButtonAtom from "../../components/atoms/ButtonAtom/ButtonAtom";
import InputAtom from "../../components/atoms/InputAtom/InputAtom";
import TextAtom from "../../components/atoms/TextAtom/TextAtom";

const LicensePlateForm = (props: IProps) => {
  // PROPERTIES
  const {
    onNextStep = () => {},
    paymentDetails = {},
    setPaymentDetails = () => {},
  } = props;

  // LIBRARY CONSTANTS
  const { parkingId } = useParams();
  // STATE CONSTANTS
  const regex = /^[A-Za-z0-9]+$/;
  const [parkingDetails, setParkingDetails] = useState<{
    title: string | null;
    subtitle: string | null;
  }>({
    title: "",
    subtitle: "",
  });

  const evaluateDisabledFirstStep =
    // true ||
    regex.test(paymentDetails.licensePlate.toUpperCase());

  // API REQUESTS
  const getParkingDetails = async () => {
    await REST.get(`/Parkings/parking-details/${parkingId}`).then(
      (response: any) => {
        localStorage.setItem("parkingTitle", response.data.title);
        localStorage.setItem("parkingSubtitle", response.data.subtitle);
        localStorage.setItem("parkingId", paymentDetails.parkingId);
        setParkingDetails({
          title: response.data.title,
          subtitle: response.data.subtitle,
        });
      }
    );
  };

  // LIFE CYCLE
  useEffect(() => {
    const fetchData = async () => {
      await getParkingDetails();
    };
    fetchData();
    // eslint-disable-next-line
  }, []);

  // EVENT HANDLERS

  const handleClick = () => {
    onNextStep();
  };

  return (
    <LicensePlateFormContainer>
      <LicensePlateFormTitleWrapper>
        <TitleWrapper>
          <TextAtom
            text={`Parcare: ${parkingDetails.title} - ${parkingDetails.subtitle}`}
            fontSize={FONT_SIZE.max16}
            color={COLOR.appBlack}
          />
        </TitleWrapper>
        <TextAtom
          text="Completează detaliile masinii"
          fontSize={FONT_SIZE.max16}
          color={COLOR.appBlack}
        />
      </LicensePlateFormTitleWrapper>
      <LicensePlateFormBodyWrapper>
        <LicensePlateFormInputWrapper>
          <InputAtom
            labelText="Numar de inmatriculare*"
            placeholder="EX:B000ABC"
            value={paymentDetails.licensePlate.toUpperCase()}
            onChange={(event) =>
              setPaymentDetails({
                ...paymentDetails,
                licensePlate: event.target.value,
              })
            }
          />
        </LicensePlateFormInputWrapper>

        <LicensePlateFormButtonWrapper>
          <ButtonAtom
            text="Continua"
            handleClick={handleClick}
            disabled={!evaluateDisabledFirstStep}
          />
        </LicensePlateFormButtonWrapper>
      </LicensePlateFormBodyWrapper>
    </LicensePlateFormContainer>
  );
};

export default LicensePlateForm;
