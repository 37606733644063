import styled from "styled-components";

export const LayoutContainer = styled.div`
  display: flex !important;
  flex-direction: column;
  height: 100vh;
  @media (width<768px) {
    width: 100%;
  }
`;

export const HeaderContainer = styled.div`
  height: 100px;
  @media (width<768px) {
    height: 100px;
  }
`;

export const ContentContainer = styled.div`
  height: 100%;
  overflow: auto;

`;

export const FooterContainer = styled.div`
  height: 100px;
  @media (width<768px) {
    height: 100px;
  }
`;
