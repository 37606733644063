import styled from "styled-components";
import { COLOR } from "../../../constants/constants";

export const TabAtomContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export const TabAtomStyled = styled.button<{ isSelected?: boolean }>`
  width: 100%;
  height: 100%;
  padding-inline: 24px;
  box-shadow: 0 1px 6px 0 ${COLOR.boxShadowGray}7f;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  cursor: pointer;
  background-color: ${COLOR.appWhite};
  border: ${(props) =>
    props.isSelected
      ? `2px solid ${COLOR.appBlue}`
      : `1px solid ${COLOR.appGrey}`};
`;
