import styled from "styled-components";
import { COLOR } from "../../../constants/constants";

export const ButtonAtomContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export const ButtonAtomStyled = styled.button<{ disabled: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 1px 6px 0 ${COLOR.boxShadowGray}7f;
  border-radius: 6px;
  border: 1px solid ${COLOR.appGrey};
  background-color: ${(props) =>
    props.disabled ? COLOR.appGrey : COLOR.appWhite};
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};

  /* transition: background-color 0.3s ease-in-out, border 0.3s ease-in-out; */

  &:focus {
    outline: 0;
    border: 1px solid ${COLOR.appBlue};
  }
`;
