import React from "react";

// ASSETS

// STYLES
import {
  ContentContainer,
  FooterContainer,
  HeaderContainer,
  LayoutContainer,
} from "./LayoutStyled";

// LIBRARIES
import { Outlet } from "react-router-dom";

// MISC

// REDUX

// COMPONENTS
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

const Layout = () => {
  // PROPERTIES

  // LIBRARY CONSTANTS

  // STATE CONSTANTS

  // API REQUESTS

  // LIFE CYCLE

  // EVENT HANDLERS

  return (
    <LayoutContainer>
      <HeaderContainer>
        <Header />
      </HeaderContainer>
      <ContentContainer>
        <Outlet />
      </ContentContainer>
      <FooterContainer>
        <Footer />
      </FooterContainer>
    </LayoutContainer>
  );
};

export default Layout;
