import styled from "styled-components";
import { COLOR, FONT, FONT_SIZE } from "../../../constants/constants";

export const SelectAtomContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const SelectButton = styled.button`
  width: 100%;
  height: 100%;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-inline: 24px;
  border: 1px solid ${COLOR.appGrey};
  border-radius: 4px;
  background-color: ${COLOR.appWhite};
  box-shadow: 0 1px 6px 0 ${COLOR.boxShadowGray}7f;
  &:focus {
    border: 1px solid ${COLOR.appBlue};
    outline: 0;
  }
`;

export const SelectTextWrapper = styled.div``;

export const SelectArrowWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const SelectArrow = styled.img<{ open: boolean }>`
  width: 20px;
  rotate: ${({ open }) => (open ? "180deg" : "0deg")};
  transition: rotate 0.3s ease-out;
`;

export const SelectMenuContainer = styled.ul<{
  open: boolean;
}>`
  list-style: none;
  position: absolute;
  width: 100%;
  display: ${({ open }) => (open ? "block" : "none")};
  z-index: 1;
  top: 100%;
  padding: 0;
  margin: 0;
  border: 1px solid ${COLOR.appGrey};
  border-radius: 4px;
  background-color: ${COLOR.appWhite};
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  max-height: 500px;

  & > *:hover {
    background-color: ${COLOR.appGrey}60;
  }
  @media (width<768px) {
    max-height: 300px;
  }
`;

export const SelectMenuItem = styled.li<{ selected: boolean }>`
  cursor: pointer;
  padding-block: 10px;
  padding: 16px 24px;
  border-radius: 4px;
  &:hover {
    background-color: ${COLOR.appGrey}60;
  }
  transition: background-color 0.3s ease-out;
`;

export const SelectLabelWrapper = styled.div``;

export const SelectAtomLabel = styled.label`
  padding-inline: 24px;
  padding-block: 4px;
  display: flex;
  align-items: center;

  font-size: ${FONT_SIZE.max10};
  font-weight: 700;
  text-transform: uppercase;
  color: ${COLOR.appBlack};
  font-family: ${FONT.gotham};
`;
