//LIBRARIES
import { Navigate, Route, Routes } from "react-router-dom";
import Layout from "../components/Layout/Layout";
import PaymentDetails from "../view/PaymentDetailsPage/PaymentDetails";
import PaymentWebView from "../view/PaymentWebViewPage/PaymentWebViewPage";
import Payments from "../view/PaymentsPage/Payments";

// COMPONENTS

const AppRoutes = () => {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="/payment-form/:parkingId" element={<Payments />} />
        <Route path="/payment-checkout" element={<PaymentWebView />} />
        <Route path="/payment-status/:id" element={<PaymentDetails />} />
      </Route>
      <Route
        path="*"
        element={
          <Navigate to={`/payment-form/${localStorage.getItem("parkingId")}`} />
        }
      />
    </Routes>
  );
};

export default AppRoutes;
