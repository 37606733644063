import React from "react";

// ASSETS

// STYLES
import { TextAtomContainer, TextAtomStyled } from "./TextAtomStyled";

// LIBRARIES

// MISC
import { IProps } from "./TextAtomModel";
import { COLOR, FONT_SIZE } from "../../../constants/constants";
// REDUX

// COMPONENTS

const TextAtom = (props: IProps) => {
  // PROPERTIES
  const {
    text = "",
    fontSize = FONT_SIZE.max16,
    fontWeight = "500",
    color = COLOR.appBlack,
  } = props;
  // LIBRARY CONSTANTS

  // STATE CONSTANTS

  // API REQUESTS

  // LIFE CYCLE

  // EVENT HANDLERS

  return (
    <TextAtomContainer>
      <TextAtomStyled fontSize={fontSize} fontWeight={fontWeight} color={color}>
        {text}
      </TextAtomStyled>
    </TextAtomContainer>
  );
};

export default TextAtom;
