import styled from "styled-components";
import { COLOR } from "../../constants/constants";

export const PaymentDetailsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 36px;
  @media (width<768px) {
    row-gap: 16px;
  }
`;

export const PaymentDetailsDoneIconWrapper = styled.div`
  width: 100px;
  aspect-ratio: 1/1;
  padding-top: 36px;
  @media (width<768px) {
    width: 80px;
    padding-top: 16px;
  }
`;

export const PaymentDetailsIcon = styled.img`
  width: 100%;
  height: 100%;
`;

export const PaymentDetailsTextWrapper = styled.div`
  height: 72px;
  width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  @media (width<768px) {
    width: 90vw;
  }
`;

export const PaymentDetailsHelpWrapper = styled.div`
  width: 350px;
  height: 126px;
  background-color: ${COLOR.confirmGeen};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (width<768px) {
    width: 90vw;
  }
`;

export const PaymentDetailsButtonWrapper = styled.div`
  height: 75px;
  width: 350px;
  margin-top: 12px;
  @media (width<768px) {
    width: 90vw;
  }
`;
