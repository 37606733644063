import React from "react";

// ASSETS

// STYLES
import {
  InputAtomContainer,
  InputAtomInput,
  InputAtomLabel,
} from "./InputAtomStyled";

// LIBRARIES

// MISC
import { IProps } from "./InputAtomModel";

// REDUX

// COMPONENTS

const InputAtom = (props: IProps) => {
  // PROPERTIES
  const {
    labelText = "",
    placeholder = "",
    type = "text",
    name = "",
    onChange = () => {},
    value = "",
  } = props;
  // LIBRARY CONSTANTS

  // STATE CONSTANTS

  // API REQUESTS

  // LIFE CYCLE

  // EVENT HANDLERS

  return (
    <InputAtomContainer>
      <InputAtomLabel>{labelText}</InputAtomLabel>
      <InputAtomInput
        type={type}
        placeholder={placeholder}
        name={name}
        onChange={onChange}
        value={value}
      />
    </InputAtomContainer>
  );
};

export default InputAtom;
