import React, { useEffect, useState } from "react";

// ASSETS

// STYLES
import {
  BillingFormBodyWrapper,
  BillingFormButtonWrapper,
  BillingFormContainer,
  BillingFormInputDoubleRow,
  BillingFormInputWrapper,
  BillingFormInputsRow,
  BillingFormIputsContainer,
  BillingFormTabWrapper,
  BillingFormTabsWrapper,
  BillingFormTitleWrapper,
} from "./BillingFormStyled";

// LIBRARIES
import { useNavigate } from "react-router-dom";

// MISC
import { IProps, BillingFormTabType } from "./BillingFormModel";
import { COLOR, FONT_SIZE } from "../../constants/constants";
import { REST } from "../../config/axiosConfig";

// REDUX

// COMPONENTS
import TextAtom from "../../components/atoms/TextAtom/TextAtom";
import InputAtom from "../../components/atoms/InputAtom/InputAtom";
import ButtonAtom from "../../components/atoms/ButtonAtom/ButtonAtom";
import TabAtom from "../../components/atoms/TabAtom/TabAtom";
import PhoneInput from "../../components/PhoneInput/PhoneInput";
import { TitleWrapper } from "../LicensePlateForm/LicensePlateFormStyled";

const BillingForm = (props: IProps) => {
  // PROPERTIES
  const { onPreviousStep = () => {}, paymentDetails = {} } = props;

  // LIBRARY CONSTANTS
  const navigate = useNavigate();

  // STATE CONSTANTS
  const [billingDetails, setBillingDetails] = useState({
    firstName: "",
    name: "",
    phone: "",
    email: "",
    street: "",
    number: "",
    city: "",
    companyName: "",
    cui: "",
    registryNumber: "",
    bankName: "",
    IBAN: "",
    billType: BillingFormTabType.PERSONAL,
  });
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phoneRegex = /^\+\d{6,15}$/;
  const evaluateDisabledSecondStep =
    billingDetails.phone?.length > 0 &&
    billingDetails.email?.length > 0 &&
    billingDetails.street?.length > 0 &&
    billingDetails.number?.length > 0 &&
    billingDetails.city?.length > 0 &&
    emailRegex.test(billingDetails.email) &&
    phoneRegex.test(billingDetails.phone) &&
    (billingDetails.billType === BillingFormTabType.PERSONAL
      ? billingDetails.firstName?.length > 0 && billingDetails.name?.length > 0
      : billingDetails.companyName?.length > 0 &&
        billingDetails.cui?.length > 0 &&
        billingDetails.registryNumber?.length > 0 &&
        billingDetails.bankName?.length > 0 &&
        billingDetails.IBAN?.length > 0);

  // API REQUESTS

  // LIFE CYCLE
  useEffect(() => {
    setButtonDisabled(!evaluateDisabledSecondStep);
    // eslint-disable-next-line
  }, [billingDetails]);
  // EVENT HANDLERS
  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBillingDetails((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const handleNext = async () => {
    setButtonDisabled(true);
    const payload = {
      parkingId: paymentDetails.parkingId,
      productId: paymentDetails.selectedProduct.key,
      licensePlate: paymentDetails.licensePlate,
      isPersonalProfile:
        billingDetails.billType === BillingFormTabType.PERSONAL ? true : false,
      ...(billingDetails.billType === BillingFormTabType.PERSONAL && {
        personalInfo: {
          firstName: billingDetails.firstName,
          lastName: billingDetails.name,
          phoneNumber: billingDetails.phone,
          email: billingDetails.email,
          street: billingDetails.street,
          number: billingDetails.number,
          city: billingDetails.city,
        },
      }),
      ...(billingDetails.billType === BillingFormTabType.BUSINESS && {
        businessInfo: {
          companyName: billingDetails.companyName,
          uniqueId: billingDetails.cui,
          fiscalNumber: billingDetails.registryNumber,
          bankName: billingDetails.bankName,
          iban: billingDetails.IBAN,
          phoneNumber: billingDetails.phone,
          email: billingDetails.email,
          street: billingDetails.street,
          number: billingDetails.number,
          city: billingDetails.city,
        },
      }),
    };
    await REST.post(`/Payment/initiate-web-payment`, payload).then(
      (response: any) => {
        const { data } = response;
        if (data.isFree) {
          navigate(`/payment-status/${data.transactionId}`, {
            state: {
              payload,
              selectedProduct: paymentDetails.selectedProduct,
              status: "Completed",
            },
          });
        } else {
          navigate(`/payment-checkout`, {
            state: {
              data,
              payload,
              selectedProduct: paymentDetails.selectedProduct,
            },
          });
        }
      }
    );
  };

  const handlePrevious = () => {
    onPreviousStep();
  };

  const handleTabChange = (type: BillingFormTabType) => {
    setBillingDetails((prevState) => ({
      ...prevState,
      billType: type,
    }));
  };

  return (
    <BillingFormContainer>
      <BillingFormTitleWrapper>
        <TitleWrapper>
          <TextAtom
            text={`Parcare: ${localStorage.getItem(
              "parkingTitle"
            )} - ${localStorage.getItem("parkingSubtitle")}`}
            fontSize={FONT_SIZE.max16}
            color={COLOR.appBlack}
          />
        </TitleWrapper>
        <TextAtom
          text="Completează detaliile de facturare"
          fontSize={FONT_SIZE.max16}
          color={COLOR.appBlack}
        />
      </BillingFormTitleWrapper>
      <BillingFormBodyWrapper>
        <BillingFormTabsWrapper>
          <BillingFormTabWrapper
            onClick={() => handleTabChange(BillingFormTabType.PERSONAL)}
          >
            <TabAtom
              title="TIP"
              subtitle="PERSONAL"
              isSelected={
                billingDetails.billType === BillingFormTabType.PERSONAL
              }
            />
          </BillingFormTabWrapper>
          <BillingFormTabWrapper
            onClick={() => handleTabChange(BillingFormTabType.BUSINESS)}
          >
            <TabAtom
              title="TIP"
              subtitle="BUSINESS"
              isSelected={
                billingDetails.billType === BillingFormTabType.BUSINESS
              }
            />
          </BillingFormTabWrapper>
        </BillingFormTabsWrapper>
        <BillingFormIputsContainer>
          {billingDetails.billType === BillingFormTabType.PERSONAL ? (
            <BillingFormInputDoubleRow>
              <BillingFormInputWrapper>
                <InputAtom
                  labelText="Prenume*"
                  value={billingDetails.firstName}
                  onChange={handleTextChange}
                  name="firstName"
                />
              </BillingFormInputWrapper>
              <BillingFormInputWrapper>
                <InputAtom
                  labelText="Nume*"
                  value={billingDetails.name}
                  onChange={handleTextChange}
                  name="name"
                />
              </BillingFormInputWrapper>
            </BillingFormInputDoubleRow>
          ) : (
            <>
              <BillingFormInputsRow>
                <BillingFormInputWrapper>
                  <InputAtom
                    labelText="Nume Companie*"
                    value={billingDetails.companyName}
                    onChange={handleTextChange}
                    name="companyName"
                  />
                </BillingFormInputWrapper>
              </BillingFormInputsRow>
              <BillingFormInputDoubleRow>
                <BillingFormInputWrapper>
                  <InputAtom
                    labelText="CUI*"
                    value={billingDetails.cui}
                    onChange={handleTextChange}
                    name="cui"
                  />
                </BillingFormInputWrapper>
                <BillingFormInputWrapper>
                  <InputAtom
                    labelText="Nr. Reg. Com.*"
                    value={billingDetails.registryNumber}
                    onChange={handleTextChange}
                    name="registryNumber"
                  />
                </BillingFormInputWrapper>
              </BillingFormInputDoubleRow>
              <BillingFormInputsRow>
                <BillingFormInputWrapper>
                  <InputAtom
                    labelText="Nume Banca*"
                    value={billingDetails.bankName}
                    onChange={handleTextChange}
                    name="bankName"
                  />
                </BillingFormInputWrapper>
              </BillingFormInputsRow>
              <BillingFormInputsRow>
                <BillingFormInputWrapper>
                  <InputAtom
                    labelText="IBAN*"
                    value={billingDetails.IBAN}
                    onChange={handleTextChange}
                    name="IBAN"
                  />
                </BillingFormInputWrapper>
              </BillingFormInputsRow>
            </>
          )}
          <BillingFormInputsRow>
            <BillingFormInputWrapper>
              <PhoneInput
                label="telefon"
                name="phone"
                value={billingDetails.phone}
                onChange={(country) =>
                  setBillingDetails((prevState) => ({
                    ...prevState,
                    phone: country as string,
                  }))
                }
              />
            </BillingFormInputWrapper>
          </BillingFormInputsRow>
          <BillingFormInputsRow>
            <BillingFormInputWrapper>
              <InputAtom
                labelText="email*"
                value={billingDetails.email}
                onChange={handleTextChange}
                name="email"
                type="email"
              />
            </BillingFormInputWrapper>
          </BillingFormInputsRow>
          <BillingFormInputsRow>
            <BillingFormInputWrapper>
              <InputAtom
                labelText="Strada*"
                value={billingDetails.street}
                onChange={handleTextChange}
                name="street"
              />
            </BillingFormInputWrapper>
          </BillingFormInputsRow>
          <BillingFormInputDoubleRow>
            <BillingFormInputWrapper>
              <InputAtom
                labelText="Numar*"
                value={billingDetails.number}
                onChange={handleTextChange}
                name="number"
              />
            </BillingFormInputWrapper>
            <BillingFormInputWrapper>
              <InputAtom
                labelText="Oras*"
                value={billingDetails.city}
                onChange={handleTextChange}
                name="city"
              />
            </BillingFormInputWrapper>
          </BillingFormInputDoubleRow>
        </BillingFormIputsContainer>

        <BillingFormButtonWrapper>
          <ButtonAtom text="Inapoi" handleClick={handlePrevious} />
          <ButtonAtom
            text="Continua"
            handleClick={handleNext}
            disabled={buttonDisabled}
          />
        </BillingFormButtonWrapper>
      </BillingFormBodyWrapper>
    </BillingFormContainer>
  );
};

export default BillingForm;
