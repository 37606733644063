// ASSETS

// STYLES
import {
  PhoneInputComponent,
  PhoneInputContainer,
  PhoneInputLabel,
} from "./PhoneInputStyled";

// LIBRARIES
import "react-phone-number-input/style.css";

// MISC
import { IProps } from "./PhoneInputModel";

// REDUX

// COMPONENTS

const PhoneInputComponenta = (props: IProps) => {
  // PROPERTIES
  const { label = "", value = "", name = "phone", onChange = () => {} } = props;
  // LIBRARY CONSTANTS

  // STATE CONSTANTS

  // API REQUESTS

  // LIFE CYCLE

  // EVENT HANDLERS

  return (
    <PhoneInputContainer>
      <PhoneInputLabel>{label}</PhoneInputLabel>
      <PhoneInputComponent
        international
        countryCallingCodeEditable={false}
        defaultCountry="RO"
        value={value}
        onChange={onChange}
        name={name}
      />
    </PhoneInputContainer>
  );
};

export default PhoneInputComponenta;
