import styled from "styled-components";
import UpParkBG from "../../assets/images/UpParkBG.png";
import { COLOR } from "../../constants/constants";

export const HeaderContainer = styled.div`
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(${UpParkBG});
`;

export const HeaderContent = styled.div`
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0,
    ${COLOR.appWhite} 100%
  );
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
